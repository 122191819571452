import React, {useState,useEffect} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import ScrollPageTop from '../../routes/ScrollPageTop'
import Seo from '../seo/seo'
import {PlainIcon} from '../../custom-icons'
import {Links} from '../../routes/PathLink'
import {APP_URL, APP_NAME, API_ENDPOINT, toast_anim_error, toast_anim} from '../config/Common'
import Input from '../../ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from '../../ui/Button.tsx'
import SelectInput from '../../ui/select-input.jsx'
import Label from '../../ui/label.tsx'


import TextArea from '../../ui/text-area.tsx'
import FileInput from '../../ui/file-input.jsx';

import classNames from 'classnames';
import * as Yup from 'yup';
import {
   useForm,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  

const FormSchema = Yup.object().shape({
   name: Yup.string()
      .required('You must need to provide your full name.')
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!'),
   phone_no: Yup.string()
      .required('You must need to provide your phone number.')
      .min(12, 'Invalid phone number.'),
   email: Yup.string()
      .required('You must need to provide your email address.')
      .email('Invalid email.'),
   job_title:Yup.object()
      .nullable()
      .required('You must need to provide Job title.'),
   address: Yup.string()
      .required('You must need to provide your full address.'),
   upload_resume: Yup.array()
      .min(1, "You must need to upload your resume")
      .required("You must need to upload your resume"),
});

let defaultValues = {  
   name: '',
   email:'',
   phone_no:'',
   job_title:'',
   address:''
   
};

const jobTitles = [
   {
      job_id:1,
      title:'CT Technician'
   },
   {
      job_id:2,
      title:'X-Ray Technician'
   },
   {
      job_id:3,
      title:'MRI Technician'
   },
   {
      job_id:4,
      title:'Bio Medical engineer'
   },
   {
      job_id:5,
      title:'Application engineer'
   },
    {
      job_id:6,
      title:'Other'
   },
]

const Carrier = () => {
   const [loading, setLoading] = useState(false)
   const [success, setSuccess] = useState(false)

   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);
   const [metaTitle, setMetaTitle] = useState('');
   const [metaDesc, setMetaDesc] = useState('');

   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(FormSchema),
   });

   const handleSelect = (val,name) => {  
      //console.log('dropdown val======',val,name.name)
      setError(name.name, null)
      setValue(name.name, val, true);
   }

    useEffect(() => {
      getPageDetails()
   },[]);

    const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/careers`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }


   const onSubmit = (values) => {
      //console.log('job form======',values)
      setLoading(true)
      let input = values;
      input = {...input, 
         job_title:values.job_title.title,
         upload_resume: values?.upload_resume[0]?.newimageforserver,
      }
      //console.log('job form======',input)
      
      axios.post(`${API_ENDPOINT}/apply_for_job`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
         }
      }).then( (result)=> {
         //console.log('data=====',result.data)
         setLoading(false)
         if(result.data.status == 1){
            toast_anim('Resume uploaded successfully')
            //reset(values);
            setSuccess(true)
            window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
         }else{
            toast_anim_error('Error in upload')
         }
      })
   }

   return(
      <ScrollPageTop>
         <Seo 
            title={`${metaTitle} - ${APP_NAME}`}
            desc={metaDesc}
            url={`${APP_URL}${Links.TESTIMONIALS}`}
            image={`./img/home-bg.jpg`}
         />

         <Header />
         <div className="px-4 py-8 bg-gray-100 dark:bg-dark lg:px-8 xl:px-16 2xl:px-20">
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-6 sm:grid-cols-2">
               <div className="mt-8">
                  <h2 className="text-2xl text-body mb-2">{pagetitle}</h2>
                  <p className="text-body mb-5" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
                  
               </div>
               
               <div className="w-full space-y-6 lg:max-w-2xl">
                  <div className={classNames('w-full p-5 md:p-8 bg-light shadow rounded mb-8 mt-8')}>
                     <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
                     Apply now
                     </h1>
                     {
                     success ?
                     <p className="my-2 text-xl text-center text-green-500">
                        Your application has been submitted successfully.<br/>We will be get back to you soon.
                     </p>
                     :
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-5">
                           <Label>Job Title <span className="text-red-500"> *</span></Label>
                           <SelectInput
                              name="job_title"                            
                              control={control}
                              getOptionLabel={(option: any) => option.title}
                              getOptionValue={(option: any) => option.title}                        
                              options={jobTitles}
                              onChange={handleSelect}
                           />
                           {errors.job_title?.message && 
                           <p className="my-2 text-xs text-start text-red-500">{errors.job_title?.message}</p>
                           }
                        </div>
                        <div className="grid grid-cols-1 gap-2 sm:gap-6 sm:grid-cols-2">
                           <Input                
                              label={`Name`}
                              {...register('name')}
                              variant="outline"
                              className="mb-5"
                              error={errors.name?.message}
                              required
                           />
                           <Input                
                              label={`Email`}
                              {...register('email')}
                              variant="outline"
                              className="mb-5"
                              type="email"
                              error={errors.email?.message}
                              required
                           />
                        </div>
                        <div className="mb-5">
                           <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                           <Controller
                              name="phone_no"
                              control={control}
                              render={({ field }) => (
                                 <PhoneInput
                                    country="in"
                                    disableDropdown
                                    countryCodeEditable={false}
                                    inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                                    dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                                    {...field}
                                 />
                              )}
                           />
                           <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
                        </div>
                        
                        <TextArea
                           label='Address'
                           {...register('address')}                        
                           variant="outline"
                           className="mb-5"
                           error={errors.address?.message}
                           required
                        />
                        <TextArea
                           label='Cover latter'
                           {...register('cover_latter')}                        
                           variant="outline"
                           className="mb-5"
                        />
                        <div className="mb-5">
                           <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Upload Resume <span className="text-red-500">*</span></label>
                           <FileInput 
                              name="upload_resume" 
                              control={control} multiple={false} 
                              helperText='Upload resume'
                              help_ext='PDF, DOC, DOCX'
                           />
                           <p className="my-2 text-xs text-start text-red-500">{errors.upload_resume?.message}</p>
                        </div>
                        <Button loading={loading}>
                           Apply <PlainIcon className="ml-2" />
                        </Button>
                     </form>
                     }
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </ScrollPageTop>
   )
}

export default Carrier