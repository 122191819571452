import React, {useState, useEffect} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT} from '../config/Common'

import ScrollPageTop from '../../routes/ScrollPageTop'
import {Links} from '../../routes/PathLink'
import Seo from '../seo/seo'
import EnquiryForm from './EnquiryForm'


const Help = (props) => {

   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);
   const [metaTitle, setMetaTitle] = useState('')
   const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/help`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('page details:======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   return(
      <ScrollPageTop>
         <Seo 
            title={`${metaTitle} - ${APP_NAME}`}
            desc={metaDesc && metaDesc.substring(0, 200)}
            url={`${APP_URL}${Links.HELP}`}
            image={`./img/home-bg.jpg`}
         />
         <Header />
         <div className="w-full bg-gray-100">
            <div className="mx-auto flex w-full max-w-7xl flex-col py-10 px-5 md:flex-row xl:py-14 xl:px-8 2xl:px-14">
               <div className="order-2 w-full shrink-0 bg-light p-5 md:order-1 md:w-72 lg:w-96">
                  
                  <div className="mb-8 flex w-full items-center justify-center overflow-hidden">
                     <img alt="Contact" src="./img/help.webp" decoding="async"    data-img="intrinsic" className="h-auto w-96" />                  
                  </div>
                  <div className="flex-col justify-center items-center">
                     <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl text-center">
                     Are you facing any problem?
                     </h1>
                     <div className='text-body text-center text-2xl'>You can contact at</div>
                     
                     <div className="text-center my-5 text-body">Email: <a className="text-heading text-accent" href="mailto:support@ctmrihub.com">support@ctmrihub.com</a></div>
                     <div className="text-center mb-5 text-body">Phone: <a className="text-heading text-accent" href={`tel:+917387869666`}>+91 73878 69666</a>, <a className="text-heading text-accent" href={`tel:+918329720772`}>+91 83297 20772</a></div>
                     
                  </div>
               </div>
               <div className="order-1 mb-8 w-full bg-light p-5 md:order-2 md:mb-0 md:p-8 ltr:md:ml-7 rtl:md:mr-7 ltr:lg:ml-9 rtl:lg:mr-9"><EnquiryForm /></div>
               
            </div>
         </div>
         <Footer />
      </ScrollPageTop>
   )
}

export default Help