import React, {useState, useEffect} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL , amount_format} from '../config/Common'

import ScrollPageTop from '../../routes/ScrollPageTop'
import {Loading} from '../../custom-icons'
import Seo from '../seo/seo'

const DealerPlans = (props) => {
   const [loading, setLoading] = useState(false)
   const [details, setDetails] = useState(null)
   const [planlist, setPlanlist] = useState(null)
   const [freeplanlist, setFreeplanlist] = useState(null)
   const [standardplanlist, setStandardplanlist] = useState(null)

   
  const [pagecontent, setPagecontent] = useState(null);
  const [pagetitle, setPageTitle] = useState(null);
  const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   useEffect(() => {
      getPageMeta()
   },[]);

   

   const getPageDetails = () => {
      setLoading(true)
      fetch(`${API_ENDPOINT}/get_dealerreg_plan_list`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('subscription plans ======',result)
         setLoading(false)
			setPlanlist(result?.data?.planlist);
        setFreeplanlist(result?.data?.freeplanlist);
        setStandardplanlist(result?.data?.standardplanlist);

        
         
         
		})
   }

   const getPageMeta = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/dealer-subscription-plans`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('Page Meta:======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   

   return(
      <ScrollPageTop>
      <Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}/about`}
         image={`${IMG_URL}${details?.image}`}
      />
      <Header />
      {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="mx-auto w-full max-w-1920 bg-light py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
         
            <h1 className="mb-4 text-xl font-bold text-heading sm:mb-5 sm:text-3xl md:text-2xl 2xl:mb-7 2xl:text-4xl">
           {pagetitle}
            </h1>
            <p className="text-body mb-5" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
           <section class="text-gray-700 body-font overflow-hidden ">
           <div class="container px-5 py-16 mx-auto flex flex-wrap">
             <div class="lg:w-1/4 mt-48 hidden lg:block">
               <div class="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
                {planlist && planlist.map((val,key) => (
                     <p class={`${key % 2 === 0 ? ' bg-gray-100 '  :  'bg-white' }  text-gray-900 w-full h-12 text-left px-4 flex items-center justify-start -mt-px`}>{val?.plan_name}</p>

                 ))}
                
               </div>
             </div>
             <div class="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-300 rounded-lg">
               <div class="lg:w-1/3 lg:mt-px w-full mb-10 lg:mb-0 border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
                 <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
                   <h2 class="text-5xl text-gray-900 font-medium leading-none mb-4 mt-2">Free</h2>
                 </div>
                 {freeplanlist && freeplanlist.map((val,key) => (
                     <p class={`${key % 2 === 0 ? ' bg-gray-100 '  :  'bg-white' }  text-gray-900 w-full h-12 text-center px-4 flex items-center justify-center -mt-px`}>{val?.plan_name}</p>

                 ))}
                
               </div>
               <div class="lg:w-1/3 lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg border-accent relative">
                 <span class="bg-accent text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
                 <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
                   <h3 class="tracking-widest">Standard</h3>
                   <h2 class="text-3xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">{amount_format(1500)} 
                     <span class="text-gray-600 text-base ml-1"> + GST /6 Months </span>
                   </h2>
                   {/*<span class="text-sm text-gray-600">Charging $456 per year</span>*/}
                 </div>
                  {standardplanlist && standardplanlist.map((val,key) => (
                     <p class={`${key % 2 === 0 ? ' bg-gray-100 '  :  'bg-white' }  text-gray-900 w-full h-12 text-center px-4 flex items-center justify-center -mt-px`}>{val?.plan_name}</p>

                 ))}
                 {/*<div class="p-6 text-center border-t border-gray-300">
                   <button class="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded">Button
                     <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                       <path d="M5 12h14M12 5l7 7-7 7"></path>
                     </svg>
                   </button>
                   <p class="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p>
                 </div> */}
               </div>

                 <div class="lg:w-1/3 lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg relative">
                 <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
                   <h3 class="tracking-widest">Standard</h3>
                   <h2 class="text-3xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">{amount_format(2222)} 
                     <span class="text-gray-600 text-base ml-1"> + GST /1 year </span>
                   </h2>
                   {/*<span class="text-sm text-gray-600">Charging $456 per year</span>*/}
                 </div>
                  {standardplanlist && standardplanlist.map((val,key) => (
                     <p class={`${key % 2 === 0 ? ' bg-gray-100 '  :  'bg-white' }  text-gray-900 w-full h-12 text-center px-4 flex items-center justify-center -mt-px`}>{val?.plan_name}</p>

                 ))}
                 {/*<div class="p-6 text-center border-t border-gray-300">
                   <button class="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded">Button
                     <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                       <path d="M5 12h14M12 5l7 7-7 7"></path>
                     </svg>
                   </button>
                   <p class="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p>
                 </div> */}
               </div>

              </div>
           </div>
         </section>
                        
         </section>
      }
      <Footer />
      </ScrollPageTop>
   )
}

export default DealerPlans