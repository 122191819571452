import React, {useState,useEffect} from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import ScrollPageTop from '../routes/ScrollPageTop'
import Seo from './seo/seo'
import {PlainIcon} from '../custom-icons'
import {Links} from '../routes/PathLink'
import SelectInput from '../ui/select-input.jsx'
import Label from '../ui/label.tsx'


import {
   APP_URL, 
   APP_NAME, 
   API_ENDPOINT, 
   toast_anim_error, 
   toast_anim,
   consultation_for
} from './config/Common'

import Input from '../ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from '../ui/Button.tsx'
import TextArea from '../ui/text-area.tsx'
import classNames from 'classnames';
import * as Yup from 'yup';

import {
   useForm,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';


import axios from 'axios';  

const FormSchema = Yup.object().shape({
   name: Yup.string()
      .required('You must need to provide full name.')
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!'),
   phone_no: Yup.string()
      .required('You must need to provide phone number.')
      .min(12, 'Invalid phone number.'),
   email: Yup.string()
      .required('You must need to provide email address.')
      .email('Invalid email.'),
   consultation_for_id:Yup.object()
      .nullable()
      .required('Consultation For is required.'),
   consultation_for_label:Yup.string().when('consultation_for_id', {
      is: (consultation_for_id) => consultation_for_id?.id == 'Other',//just an e.g. you can return a function
      then: Yup.string().required('Consultation For is required'),
      otherwise:Yup.string(),
   }),
});

let defaultValues = {  
   name: '',
   email:'',
   phone_no:'',
   description:''
};




const FreeConsultation = () => {
   const [loading, setLoading] = useState(false)
   const [success, setSuccess] = useState(false)
   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);
   const [metaTitle, setMetaTitle] = useState('')
   const [metaDesc, setMetaDesc] = useState('')
   

   const [consultation_for_id , setConsultationForId] = useState('');
   const { register, handleSubmit , control, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(FormSchema),
   });
   

    useEffect(() => {
      getPageDetails()
   },[]);


    const handleSelect = (val,name) => {  
      //console.log('dropdown val======',val,name.name);
       if(name.name === 'consultation_for_id'){
         
         setValue('consultation_for_id', val, true);
         setConsultationForId(val?.id);
      }
   }

   const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/ask-for-consultation`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   const onSubmit = (values) => {
      console.log('job form======',values)
      setLoading(true)
      let input = values;
      
      console.log('job form======',input)
      
      axios.post(`${API_ENDPOINT}/free_consultation`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
         }
      }).then( (result)=> {
         //console.log('data=====',result.data)
         setLoading(false)
         if(result.data.status == 1){
            toast_anim('Application submitted')
            //reset(values);
            setSuccess(true)
            window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
         }else{
            toast_anim_error('Error in submit')
         }
      })
   }

   return(
      <ScrollPageTop>
         <Seo 
            title={`${metaTitle} - ${APP_NAME}`}
            desc={metaDesc && metaDesc.substring(0, 200)}
            url={`${APP_URL}${Links.FREE_CONSULTATION}`}
            image={`./img/home-bg.jpg`}
         />

         <Header />
         <div className="px-4 py-8 bg-gray-100 dark:bg-dark lg:px-8 xl:px-16 2xl:px-20">
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-6 sm:grid-cols-2">
              
               <div className="mt-8">
                  <h2 className="text-2xl text-body mb-2">{pagetitle}</h2>
                  <p className="text-body mb-5" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
                  
               </div>
               
               <div className="w-full space-y-6 lg:max-w-2xl">
                  <div className={classNames('w-full p-5 md:p-8 bg-light shadow rounded mb-8 mt-8')}>
                     <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
                     Ask for consultation
                     </h1>
                     {
                     success ?
                     <p className="my-2 text-xl text-center text-green-500">
                        Your application has been submitted successfully.<br/>We will be get back to you soon.
                     </p>
                     :
                     <form onSubmit={handleSubmit(onSubmit)}>
                        
                        <div className="grid grid-cols-1 gap-2 sm:gap-6 sm:grid-cols-2">
                           <Input                
                              label={`Name`}
                              {...register('name')}
                              variant="outline"
                              className="mb-5"
                              error={errors.name?.message}
                              required
                           />
                           <Input                
                              label={`Email`}
                              {...register('email')}
                              variant="outline"
                              className="mb-5"
                              type="email"
                              error={errors.email?.message}
                              required
                           />
                        </div>
                        <div className="mb-5">
                           <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                           <Controller
                              name="phone_no"
                              control={control}
                              render={({ field }) => (
                                 <PhoneInput
                                    country="in"
                                    disableDropdown
                                    countryCodeEditable={false}
                                    inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                                    dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                                    {...field}
                                 />
                              )}
                           />
                           <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
                        </div>
                        
                        <div className="mb-5">
                           <Label>Consultation For <span className="text-red-500"> *</span></Label>
                           <SelectInput
                              name="consultation_for_id"                            
                              control={control}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}                        
                              options={consultation_for}
                              onChange={handleSelect}
                           />
                           {consultation_for_id == 'Other'?
                           (<Input                
                              {...register('consultation_for_label')}
                              variant="outline"
                              className="mb-5"
                              type="text"
                              placeholder="Consultation For"
                              error={errors.consultation_for_label?.message}
                           />)
                           :
                           (null)
                           }
                           {errors.consultation_for_id?.message && 
                           <p className="my-2 text-xs text-start text-red-500">{errors.consultation_for_id?.message}</p>
                           }
                        </div>
                        
                        <Button loading={loading}>
                           Submit <PlainIcon className="ml-2" />
                        </Button>
                     </form>
                     }
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </ScrollPageTop>
   )
}

export default FreeConsultation